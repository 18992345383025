<template>
  <window-content>
    <div class="wrapper-sm">
      <e-row mr>
        <e-col style="min-width: 45%;">
          <date-input label="Data inicial (De)" v-model="filters.data1" />
        </e-col>

        <e-col style="min-width: 45%;">
          <date-input label="Data final (Até)" v-model="filters.data2" />
        </e-col>

        <e-col style="min-width: 90%">
          <erp-s-field
              view="tl"
              label="Reboquista"
          >
            <reboquista-input v-model="filters.reboquista" />
          </erp-s-field>
        </e-col>

      </e-row>
    </div>
    <window-footer class="text-right">
      <div class="inline-block window-actions">
        <u-btn label="Cancelar" icon="chevron-left" icon-type="fa" icon-style="light" flat no-caps
               @click="$uloc.window.close($root.wid)" />
        <u-btn label="Gerar" icon="print" icon-type="fa" icon-style="light" flat no-caps />
      </div>
    </window-footer>
  </window-content>
</template>

<script>
import {
  WindowContent,
  WindowHeader,
  WindowBody,
  WindowFooter,
  ErpSField,
  ErpInput,
  HelperInputBtn,
  ErpBox,
  ErpLabel
  /*ErpSelect*/
} from 'uloc-vue-plugin-erp'
import ERow from "@/components/layout/components/Row"
import ECol from "@/components/layout/components/Col"
import DateInput from "@/reuse/input/Date"
import ReboquistaInput from "@/components/reboquista/helpers/input/ReboquistaInput"

let filters = {
  data1: null,
  data2: null,
  reboquista: null,
}

export default {
  name: 'RelatorioEstoqueWindow',
  data () {
    return {
      filters: JSON.parse(JSON.stringify(filters))
    }
  },
  mounted () {
  },
  computed: {},

  components: {
    ReboquistaInput,
    DateInput,
    ECol,
    ERow,
    WindowFooter,
    // WindowBody,
    // WindowHeader,
    WindowContent,
    ErpSField,
    // ErpInput,
    // HelperInputBtn,
    // ErpBox,
    // ErpLabel
    // UEditor,
    // ErpSelect
  }
}
</script>
